<template>
  <v-container fluid grid-list-md>
    <v-row>
      <div class="title">{{ $tc('label.linha_investimento', 2) }}</div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon
            id="rateio-botao-exportar"
            v-on="on"
            @click="exportarSpreadsheet">
            <v-icon>get_app</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('label.exportar_conteudo') }}</span>
      </v-tooltip>
    </v-row>
    <v-row>
      <spreadsheet
        ref="spreadsheet"
        selectRange="A2"
        v-if="planilhaPronta"
        :toolbar="false"
        :tamanhoColunas="tamanhoColunas"
        :sheetsbar="false"
        :rows="qtdeLinhas"
        :data="datasource"
        :colunas="colunas"
        :estilos="estilos"
        :frozenRows="1"
        :autocomplete="true"/>
    </v-row>
  </v-container>
</template>
<script>
import Spreadsheet from '@/produto/shared-components/spreadsheet/Spreadsheet';
import { toColumnName } from '@/produto/common/functions/helpers';
import InputNumber from '@/produto/shared-components/inputs/InputNumber';
import OrcamentoConfiguracao from '@/produto/spa/orcamento/OrcamentoConfiguracao';

export default {
  components: {
    Spreadsheet,
    InputNumber,
  },
  props: {
    edicao: Boolean,
    somenteLeitura: Boolean,
    ajusteVerba: {
      type: Object,
      required: true,
    },
  },
  computed: {
    linhasInvestimento() {
      return this.ajusteVerba.linhas;
    },
    isRateioDivisao() {
      return this.ajusteVerba.linhas
        && this.ajusteVerba.linhas.length
        && this.ajusteVerba.linhas[0].divisao !== undefined;
    },
    colunasBase() {
      const colunasPadrao = [
        {
          field: 'descricao',
          title: `${this.$tc('label.linha', 1)}`,
        },
        {
          field: 'tipoInvestimento',
          title: `${this.$tc('label.tipo_de_investimento', 1)}`,
        },

      ];

      if (this.isRateioDivisao) {
        colunasPadrao.push(
          {
            field: 'divisao',
            title: `${this.$tc('label.divisao', 1)}`,
          },
        );
      } else {
        colunasPadrao.push(
          {
            field: 'holding',
            title: `${this.$tc('label.holding', 1)}`,
          },
          {
            field: 'hierarquia1',
            title: `${this.$tc('label.hierarquia', 1)} 1`,
          },
          {
            field: 'hierarquia2',
            title: `${this.$tc('label.hierarquia', 1)} 2`,
          },
          {
            field: 'hierarquia3',
            title: `${this.$tc('label.hierarquia', 1)} 3`,
          },
          {
            field: 'produto',
            title: this.$tc('label.produto', 1),
          },
        );
      }
      return colunasPadrao;
    },
  },
  data() {
    return {
      ajusteVerbaResource: this.$api.ajusteVerbaReducaoMassiva(this.$resource),
      extensaoResource: this.$api.extensao(this.$resource),
      configuracaoResource: this.$api.orcamentoConfiguracao(this.$resource),

      planilhaPronta: false,

      datasource: [],
      colunas: [],
      tamanhoColunas: [],
      estilos: [],
      formulas: [],
      qtdeLinhas: 50,

      estiloTitulo: {
        textAlign: 'center',
        bold: true,
        enable: false,
        background: this.$vuetify.theme.currentTheme.primary,
        color: 'rgb(255,255,255)',
      },
      estiloSomenteLeituraTexto: { enable: false, color: 'rgb(0,0,0)' },
      estiloSomenteLeituraNro: { enable: false, background: 'rgb(191,191,191)', color: 'rgb(0,0,0)' },
    };
  },
  methods: {
    carregarConfiguracao(idTipoVerba) {
      const idOrcamento = this.ajusteVerba.orcamento.id;

      // console.log('Dados: ', { idOrcamento, idTipoVerba, ajusteVerba: this.ajusteVerba });

      const promise = this.novo
        ? this.configuracaoResource.buscarConfigVigente({ idTipoVerba })
        : this.configuracaoResource.buscarConfigSnapshot({ idOrcamento });

      promise.then((res) => {
        this.configuracao = new OrcamentoConfiguracao(res.data);

        this.iniciaPlanilha();
      })
        .catch((err) => {
          this.$toast(err);
        });
    },
    refazerSpreadsheet() {
      this.planilhaPronta = false;
      setTimeout(() => this.iniciaPlanilha());
    },
    exportarSpreadsheet() {
      this.$refs.spreadsheet.exportarExcel();
    },
    getCelulaEntidade(entidade) {
      switch (entidade) {
        case 'descricao':
          return 'A';
        case 'tipo_investimento':
          return 'B';
        case 'divisao':
          return 'C';
        case 'holding':
          return 'C';
        case 'chave':
          return 'B';
        case 'hierarquia':
          return 'D';
        default:
          return 'E';
      }
    },
    iniciaPlanilha() {
      this.montarColunas();
      this.preencheDados();
      this.aplicaEstiloColunas();
      this.planilhaPronta = true;
    },
    preencheDados() {
      this.datasource = [...this.linhasInvestimento];
      this.qtdeLinhas = this.linhasInvestimento ? (this.linhasInvestimento.length + 1) : 0;
    },
    colunaPeriodo() {
      return {
        field: 'valorPeriodo',
        title: `${this.$tc('label.valor_reducao_para', 1)} ${this.ajusteVerba.periodoOrcamentario.descricao}`,
      };
    },
    colunasCC() {
      return [
        {
          field: 'planejado',
          title: this.$tc('label.valor_planejado'),
        },
        {
          field: 'saldo',
          title: this.$tc('label.saldo'),
        },
      ];
    },
    montarColunas() {
      this.colunas = [
        ...this.colunasBase,
        this.colunaPeriodo(),
        ...this.colunasCC(),
      ];
    },
    aplicaEstiloColunas() {
      this.estilos = [];
      this.formulas = [];
      this.aplicaTamanhoColunas();
      this.aplicaEstiloCabecalho();
      this.aplicaEstiloDesabilitados();
      this.aplicaEstiloCampoNumerico();
      // this.aplicaEstiloUltimaLinha();
    },
    aplicaTamanhoColunas() {
      this.tamanhoColunas = [
        { width: 300 },
        { width: 200 },
        { width: 250 },
        { width: 250 },
        { width: 250 },
        { width: 250 },
        { width: 250 },
        { width: 200 },
        { width: 200 },
        { width: 200 },
      ];
    },
    aplicaEstiloCabecalho() {
      const letraInicial = toColumnName(0);
      const letraFinal = toColumnName(this.colunas.length - 1);
      this.estilos.push({ range: `${letraInicial}1:${letraFinal}1`, metodos: this.estiloTitulo });
    },
    aplicaEstiloDesabilitados() {
      if (!this.somenteLeitura) {
        this.estilos.push(
          {
            range: `A2:C${this.datasource.length + 1}`,
            metodos: this.estiloSomenteLeituraTexto,
          },
          {
            range: `I2:J${this.qtdeLinhas}`,
            metodos: this.estiloSomenteLeituraNro,
          },
        );
      } else {
        this.estilos.push(
          {
            range: `A2:D${this.qtdeLinhas}`,
            metodos: this.estiloSomenteLeituraTexto,
          },
          {
            range: `I2:J${this.qtdeLinhas}`,
            metodos: this.estiloSomenteLeituraNro,
          },
        );
      }
    },
    aplicaEstiloUltimaLinha() {
      const letraInicial = toColumnName(0),
        letraFinal = toColumnName(this.colunas.length - 1),
        numeroLinha = this.qtdeLinhas;

      this.estilos.push({
        range: `${letraInicial}${numeroLinha}:${letraFinal}${numeroLinha}`,
        metodos: this.estiloSomenteLeituraTexto,
      });
    },
    aplicaEstiloCampoNumerico() {
      const max = 9999999999.99,
        min = 0.00;
      if (this.configuracao.ehPercentual) {
        this.estilos.push({
          range: `H2:J${this.qtdeLinhas}`,
          metodos: { format: '[>=0][BLACK]###,##0.00%;[RED]###,##0.00%' },
        });
      } else {
        this.estilos.push({
          range: `H2:J${this.qtdeLinhas}`,
          metodos: { format: '[>=0][BLACK]###,##0.00;[RED]###,##0.00' },
        });
      }
      this.formulas.push({
        range: `H2:J${this.qtdeLinhas}`,
        metodos: {
          validation: {
            allowNulls: true,
            comparerType: 'between',
            from: min,
            to: max,
            dataType: 'number',
            type: 'reject',
            messageTemplate:
              this.$t('message.intervalo_numerico', { min, max }),
            titleTemplate: this.$tc('title.erro', 1),
          },
        },
      });
    },
    valida() {
      const datasource = this.$refs.spreadsheet.getDataSource();
      let valido = datasource
        .map((r) => r.valorPeriodo || 0)
        .reduce((a, b) => a + b, 0) > 0;

      if (!valido) {
        this.$toast(this.$t('errors.reducao.valor_reduzir_menor_zero'));
        return valido;
      }

      datasource.forEach((r) => {
        if (r.valorPeriodo > 0 && r.saldo < r.valorPeriodo) {
          valido = false;
        }
      });

      if (!valido) {
        this.$toast(this.$t('errors.reducao.valor_reduzir_maior_saldo'));
      }

      return valido;
    },
    linhasValidas() {
      const dataSource = this.$refs.spreadsheet.getDataSource();
      // completa o array de linhas com o idLinhaInvestimento caso array > 50
      if (dataSource && dataSource.length > 50) {
        for (let i = 0; i < dataSource.length; i += 1) {
          const row = dataSource[i];
          if (row && !row.idLinhaInvestimento && row.descricao
          && (this.datasource[i] && row.descricao === this.datasource[i].descricao)) {
            row.idLinhaInvestimento = this.datasource[i].idLinhaInvestimento;
          }
        }
      }
      // Filtra as linhas que possuem algo preenchido
      return dataSource
        .map((r) => JSON.parse(JSON.stringify(r)))
        .filter((r) => Object.keys(r).filter((k) => !!r[k]).length > 0);
    },
  },
  mounted() {
    this.carregarConfiguracao(this.ajusteVerba.tipoVerba.id);
  },
};
</script>
